<template>
  <div>
    <el-row type="flex" justify="center" v-loading="loading">
        <el-col :span="20" :offset="2" :xs="24" :sm="24" :md="24" :lg="18" :xl="14">
          <!-- Бүх хуудсанд ашиглагдах header section -->
          <custom-header :title="discountData ? discountData.title_mon : ''"/>
          <loader :active="loading"/>
          <el-row :gutter="20">
            <el-row :gutter="20" class="pt40 pb30">
              <el-col :span="20" v-if="discountData.status === 'active'">
                <el-steps :active="2" finish-status="success" :space="500" align-center style="justify-content: center">
                  <el-step :title="discountData ? discountData.start_date : ''"></el-step>
                  <el-step :title="discountData ? discountData.end_date : ''"></el-step>
                </el-steps>
              </el-col>
              <el-col :span="20" v-if="discountData.status === 'expired'">
                <el-steps :active="2" finish-status="wait" :space="500" align-center style="justify-content: center">
                  <el-step :title="discountData ? discountData.start_date : ''"></el-step>
                  <el-step :title="discountData ? discountData.end_date : ''"></el-step>
                </el-steps>
              </el-col>
            </el-row>
            <el-col :span="20">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <header>Үндсэн мэдээлэл</header>
                    </el-col>
                    <el-col :span="8" class="text-right" v-if="Object.prototype.hasOwnProperty.call(discountData, 'status')">
                      <el-tag size="medium" v-if="discountData.status === 'active'" type="success" effect="dark">Active</el-tag>
                      <el-tag size="medium" v-else-if="discountData.status === 'expired'" type="info" effect="dark">Expired</el-tag>
                      <el-tag size="medium" v-else type="warning" effect="dark">{{discountData.status}}</el-tag>
                    </el-col>
                    <el-col :span="4">
                      <el-select v-model="setLanguage" placeholder="English" size="mini">
                        <el-option
                          v-for="item in Language"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <div class="panel-item  border-top">
                    <el-row :gutter="20">
                      <el-col :span="8">
                        <span><strong>Урамшуулалын хэлбэр:</strong></span>
                      </el-col>
                      <el-col :span="12" v-if="discountTypes.find(item => item.value === discountData.type)">
                        <span>{{ discountTypes[discountTypes.findIndex(item => item.value === discountData.type)].label }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="discountData.discount_code">
                      <el-col :span="8">
                        <span><strong>Купон код:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <el-tag type="primary" effect='plain'>{{ discountData.discount_code }}</el-tag>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Цацах суваг:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.channel }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="discountData.usage_limit !== null">
                      <el-col :span="8">
                        <el-tooltip placement="top" effect="dark">
                          <div slot="content">Тухайн урамшуулалыг нийт хэдэн удаа ашиглах хязгаар.</div>
                          <span><strong>Хэрэглээний хязгаар:</strong></span>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.usage_limit }} удаа</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="discountData.one_user_limit !== null">
                      <el-col :span="8">
                        <span><strong>Нэг хэрэглэгч хэдэн удаа хэрэглэх:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span><i class="el-icon-user" /> - {{ discountData.one_user_limit }} удаа</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20"  v-if="setLanguage === 'mongolia'">
                      <el-col :span="8">
                        <span><strong>Монгол нэр:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.title_mon }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="setLanguage === 'english'">
                      <el-col :span="8" >
                        <span><strong>Англи нэр:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.title_eng }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20"  v-if="setLanguage === 'mongolia'">
                      <el-col :span="8">
                        <span><strong>Монгол тайлбар:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.desc_mon }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="setLanguage === 'english'">
                      <el-col :span="8">
                        <span><strong>Англи тайлбар:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ discountData.desc_eng }}</span>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <div class="panel" v-if="Object.prototype.hasOwnProperty.call(discountData, 'products')">
                <div class="panel-item">
                  <el-tooltip placement="top" effect="dark">
                    <div  iv slot="content">Хэрэглэгч энэхүү нөхцлийг хангаж байж урамшуулалд хамрагдах боломжтой болно.<br/>Жишээ нь: Тухайн бараанаас тэдийг авбал гэх мэт ...</div>
                    <header style="width: 80px;">Нөхцөл</header>
                  </el-tooltip>
                  <div class="panel-item border-top">
                    <div v-if="discountData.brands.length > 0">
                      <el-row>
                       <el-col class="mt20">
                        <span><strong>Дараахь брэндээс хэдэн ширхэгийг авбал</strong></span>
                       </el-col>
                      </el-row>
                        <div class="panel mt20" v-for="(brand, index) in discountData.brands" :key="index">
                          <div class="panel-item">
                            <el-row :gutter="20">
                              <el-col :span="12">
                                {{brand.brand_mon_name}}
                              </el-col>
                              <el-col :span="12">
                                <span>{{brand.min_count}}ш</span>
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                      </div>
                    <div v-if="discountData.suppliers.length > 0">
                      <el-row class="mt20">
                        <span><strong>Дараахь нийлүүлэгчээс хэдэн ширхэгийг авбал</strong></span>
                      </el-row>
                        <div class="panel mt20" v-for="(supplier, index) in discountData.suppliers" :key="index">
                          <div class="panel-item">
                            <el-row :gutter="20">
                              <el-col :span="12">
                                {{supplier.supplier_mon_name}}
                              </el-col>
                              <el-col :span="12">
                                <span>{{supplier.min_count}}ш</span>
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                      </div>
                      <div  v-if="discountData.categories.length > 0">
                        <el-row class="mt20">
                          <el-col :span="8">
                            <span><strong>Дараахь категоруудаас хэдэн ширхэгийг авбал:</strong></span>
                          </el-col>
                          <el-col :span="16">
                            <span>{{ discountData.categories[0].min_count }}ш</span>
                          </el-col>
                        </el-row>
                        <el-row class="mt20">
                          <span v-for="(category, index) in discountData.categories" :key="index" class="mr10">
                            <el-tag type="warning">{{category.name_mon}}</el-tag>
                          </span>
                        </el-row>
                      </div>
                      <div v-if="discountData.products.length > 0">
                        <el-row>
                        <el-col class="mt10">
                          <span><strong>Дараахь бараануудаас хэдэн ширхэгийг авбал</strong></span>
                        </el-col>
                        </el-row>
                        <el-row :gutter="20" class="mt20">
                              <div class="panel" v-for="(product, index) in discountData.products" :key="index">
                                <div class="panel-item">
                                  <el-row :gutter="5" type="flex" align="middle">
                                    <el-col :span="4" :xl="4" :lg="4" :md="6" :sm="6" :xs="6">
                                      <div class="image-holder">
                                        <img :src="product.variant_image" alt="">
                                      </div>
                                    </el-col>
                                    <el-col :span="4">
                                      <div class="text-color-secondary">{{product.product_name}}</div>
                                      <div>{{product.variant_name}}</div>
                                      <div class="text-color-placeholder">{{product.variant_sku}}</div>
                                    </el-col>
                                    <el-col :span="4" :offset="10">
                                      <span>{{product.min_count}}ш</span>
                                    </el-col>
                                  </el-row>
                                </div>
                              </div>
                        </el-row>
                      </div>
                    <el-row :gutter="20" class="mt20" v-if="discountData.min_pay_total !== null">
                      <el-col :span="8">
                        <span><strong>Худалдан авалтын дүнгийн хязгаар:</strong></span>
                      </el-col>
                      <el-col :span="6">
                        <span>min - {{discountData.min_pay_total}}₮</span>
                      </el-col>
                      <el-col :span="6">
                        <span>max - {{discountData.max_pay_total}}₮</span>
                      </el-col>
                    </el-row>
                  </div>
              </div>
              </div>
              <div class="panel" v-if="Object.prototype.hasOwnProperty.call(discountData, 'discount')">
                <div class="panel-item">
                      <el-tooltip placement="top" effect="dark">
                        <div slot="content">Дээрхи нөхцөлийг хангасан хэрэглэгчид ямар төрлөөр урамшуулахыг харуулж байна.<br/>Жишээ нь: Бүтээгдэхүүнээр урамшуулах /Гар утас 5 ширхэгийг бэлэглэх гэх мэт .../</div>
                      <header style="width: 120px;">Урамшуулал</header>
                    </el-tooltip>
                  <div class="panel-item border-top">
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                          <span><strong>Урамшуулалын төрөл:</strong></span>
                      </el-col>
                      <el-col :span="12" v-if="discountSubTypes.find(item => item.value === discountData.discount_type)">
                        <span><i v-if="discountData.discount_type === 'FREE_DELIVERY'" class="el-icon-bicycle"></i> {{ discountSubTypes[discountSubTypes.findIndex(item => item.value === discountData.discount_type)].label }}</span>
                      </el-col>
                    </el-row>
                    <div v-if="discountData.discount !== null && discountData.discount.free_products">
                        <el-row :gutter="20">
                          <el-col :span="8" class="mt20">
                            <span><strong>Дараахь бүтээгдэхүүнээс</strong></span>
                          </el-col>
                        </el-row>
                        <!-- <div class="mt20 panel" v-for="(product, index) in discountData.discount.free_products" :key="index">
                          <div class="panel-item">
                          <el-row :gutter="20">
                            <el-col :span="16">
                              <el-tag>
                                Item
                              </el-tag>
                            </el-col>
                            <el-col :span="6" :offset="2">
                              <span>{{product.count}}ш</span>
                            </el-col>
                          </el-row>
                          </div>
                        </div> -->
                        <div class="panel mt20" v-for="(product, index) in discountData.discount.free_products" :key="index">
                          <div class="panel-item">
                            <el-row :gutter="5" type="flex" align="middle">
                              <el-col :span="4" :xl="4" :lg="4" :md="6" :sm="6" :xs="6">
                                <div class="image-holder">
                                  <img :src="product.variant_image" alt="">
                                </div>
                              </el-col>
                              <el-col :span="4">
                                <div class="text-color-secondary">{{product.product_mon_name}}</div>
                                <div>{{product.variant_name}}</div>
                              </el-col>
                              <el-col :span="4" :offset="10">
                                <span>{{product.count}}ш</span>
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                    </div>
                    <el-row :gutter="20" class="mt20" v-if="discountData.discount !== null && discountData.discount.saled_percentage">
                      <el-col :span="8">
                        <span><strong>Хямдрах хувь:</strong></span>
                      </el-col>
                      <el-col :span="12" v-if="discountData.discount !== null && Object.prototype.hasOwnProperty.call(discountData.discount, 'saled_percentage')">
                        <span>{{ discountData.discount.saled_percentage }}%</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="discountData.discount !== null && discountData.discount.saled_price">
                      <el-col :span="8">
                        <span><strong>Хямдрах дүн:</strong></span>
                      </el-col>
                      <el-col :span="12" v-if="discountData.discount !== null && Object.prototype.hasOwnProperty.call(discountData.discount, 'saled_price')">
                        <span>{{ discountData.discount.saled_price }}₮</span>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="text-left">
                <el-button type="danger" @click="confirmDiscountDialog = true"
                  >Устгах
                </el-button>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="text-right">
                <el-button type="default" @click="goBack"
                  >Буцах
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    <el-dialog
    title="Хямдрал устгах"
    :visible.sync="confirmDiscountDialog"
    width="50%"
    >
      <div>
        <strong>{{discountData.title_mon}}</strong> хямдралыг устгахдаа итгэлтэй байна уу?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmDiscountDialog = false" class="mr10"
          >Болих</el-button
        >
        <el-button :disabled="isDisabled" type="danger" @click="deleteDiscount(discountData.id)">
          Устгах
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import CustomHeader from '../../../components/common/customHeader'
import Loader from '../../../components/common/loader'
export default {
  name: 'detailDiscount',
  components: {
    CustomHeader,
    Loader
  },
  data () {
    return {
      isDisabled: false,
      confirmDiscountDialog: false,
      treeDefaultProps: {
        children: 'childrens',
        label: 'name_mon'
      },
      loading: false,
      setLanguage: '',
      Language: [
        {
          value: 'english',
          label: 'Англи'
        },
        {
          value: 'mongolia',
          label: 'Монгол'
        }
      ],
      discountSubTypes: [
        {
          value: 'PERCENTAGE',
          label: 'Хувиар %'
        },
        {
          value: 'FIXED_AMOUNT',
          label: 'Үнийн дүнгээр'
        },
        {
          value: 'FREE_DELIVERY',
          label: 'Үнэгүй хүргэлт'
        },
        {
          value: 'PRODUCT',
          label: 'Бүтээгдэхүүн'
        }
      ],
      discountItemTypes: [
        {
          value: 'all_product',
          label: 'Бүх бүтээгдэхүүн'
        },
        {
          value: 'products',
          label: 'Сонгосон бүтээгдэхүүн'
        },
        {
          value: 'categories',
          label: 'Сонгосон ангилалын бүх бүтээгдэхүүн'
        },
        {
          value: 'suppliers',
          label: 'Сонгосон нийлүүлэгчийн бүх бүтээгдэхүүн'
        },
        {
          value: 'brands',
          label: 'Сонгосон брендийн бүх бүтээгдэхүүн'
        }
      ],
      discountTypes: [
        {
          value: 'DISCOUNT_CODE',
          label: 'Купон код'
        },
        {
          value: 'AUTO',
          label: 'Автоматаар бодогдох'
        }
      ],

      discountData: {}
    }
  },

  mounted () {
    if (this.$route.params.id) {
      this.getOneDiscount(this.$route.params.id)
    }
  },

  methods: {
    deleteDiscount (id) {
      this.loading = true
      this.isDisabled = true
      services.deleteDiscount(id).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', `${id} дугаартай хямдрал устлаа`, 'success')
          this.$router.push({
            name: 'codeDiscount'
          })
          this.loading = false
          this.isDisabled = false
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', `${id} дугаартай хямдралыг устгахад алдаа гарлаа`, 'error')
          this.loading = false
          this.isDisabled = false
        }
      })
    },

    filterNode (value, data) {
      if (!value) return true
      return this.discountData.categories.name_mon.indexOf(value) !== -1
    },

    goBack () {
      this.$router.go(-1)
    },

    getOneDiscount (id) {
      this.loading = true
      services.getOneDiscount(id).then((response) => {
        if (response.status === 'success') {
          this.discountData = response.data
          if (this.discountData.end_date === null) {
            this.discountData.end_date = 'Хязгааргүй'
          }
          this.loading = false
        }
      })
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>
